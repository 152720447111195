<template>
  <transition name="fade" appear>
    <div style="transition-delay: 200ms">
      <x-label>{{ $t('section.recommended-trips') }}</x-label>
      <transition-group
        name="dropdown-s"
        appear
        tag="div"
        class="recommended-trip-section"
      >
        <button
          v-for="(location, index) in featuredLocations"
          :key="location.arrivalLocation.id"
          column
          class="recommended-trip-card"
          :style="{ transitionDelay: 160 * index + 'ms' }"
          @click="selectTrip(location)"
        >
          <div style="padding: 1.5rem; height: 100%">
            <img
              :src="location.arrivalLocation.imageUrl"
              alt="Location"
              class="recommended-trip-image"
            />
            <div class="recommended-trip-img-bg"></div>
            <div class="recommended-trip-text">
              <p class="recommended-trip-text-city">
                {{ location.arrivalLocation.display.city }}
              </p>
              <p class="recommended-trip-text-country">
                {{ location.arrivalLocation.display.regionCountry }}
              </p>
            </div>
          </div>
        </button>
      </transition-group>
    </div>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex'
import { SEARCH } from '../../constants/SEARCH'
import { appAnalytics } from '../../services/analytics/analytics'

export default {
  name: 'FeaturedLocationsSection',
  props: { featuredLocations: Array },
  methods: {
    ...mapMutations('SearchForm', ['setParams']),
    selectTrip({
      children,
      infants,
      adults,
      returnDate,
      departureDate,
      departureLocation,
      arrivalLocation,
      budgetTier,
    }) {
      const searchParams = {
        searchType: SEARCH.TYPE.COMBO,
        tier: budgetTier,
        departure: departureLocation.id,
        departureLocation: departureLocation,
        destination: arrivalLocation.id,
        destinationLocation: arrivalLocation,
        departureDate: departureDate.split(' ')[0],
        returnDate: returnDate.split(' ')[0],
        children,
        adults,
        infants,
      }
      this.setParams(searchParams)
      appAnalytics.track('recommended_trip_click')
      this.$router.push('/search/form')
    },
  },
}
</script>

<style scoped lang="scss">
.recommended-trip-card {
  color: white;
  position: relative;
  min-height: 200px;
  border-radius: 9px;
  cursor: pointer;
  overflow: hidden;
  clip-path: content-box;
}
.recommended-trip-section {
  display: grid;
  gap: 0.5rem;
  margin-top: 1rem;
  min-width: 306px;
  grid-template-columns: repeat(auto-fill, minmax(306px, 1fr));
}
.recommended-trip-image {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}
.recommended-trip-img-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-145deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}
@media (hover: hover) {
  .recommended-trip-card:hover,
  .recommended-trip-card:focus {
    & > div > .recommended-trip-img-bg {
      opacity: 0.3;
    }
    & > div > .recommended-trip-image {
      transform: scale(1.02);
    }
  }
}
.recommended-trip-text {
  position: relative;
  height: 100%;
  text-align: start;
  text-shadow: var(--text-shadow);
  &-city {
    font-style: italic;
    font-family: GTSuper;
    font-size: 2.75rem;
  }
  &-country {
    font-family: GTSuper;
    font-size: 1.2rem;
    -webkit-text-stroke: 0.2px white;
  }
}
</style>
