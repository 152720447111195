<template>
  <transition name="fade" appear>
    <div style="transition-delay: 150ms">
      <x-label>{{ $t('section.upcoming-trip') }}</x-label>
      <div
        style="
          display: grid;
          grid-template-columns: 100px 1fr;
          grid-template-rows: 1fr auto 1fr;
          grid-template-areas: 'img title' 'img break' 'img desc';
        "
        class="x-wrap upcoming-trips"
        @click="gotoTripDetails"
      >
        <div class="x-img relative">
          <img
            :src="imageUrl"
            alt="City"
            class="image-center center-absolute"
            style="width: 100%; height: 100%"
          />
          <div class="x-img-bg"></div>
          <p class="x-img-text center-absolute">
            {{ arriveCityIata }}
          </p>
        </div>
        <div class="x-title">
          <p class="fs-16" style="font-weight: bold">
            {{ departureDate }} - {{ returnDate }}
          </p>
          <p class="fs-16" style="text-transform: uppercase">
            {{ arriveCityName }},
            {{ arriveCountryName }}
          </p>
        </div>
        <div class="x-break"></div>
        <div class="x-depart">
          <p style="font-family: DDin">COUNTDOWN</p>
          <div
            class="x-depart-time"
            style="
              display: grid;
              gap: 0.3rem;
              grid-template-columns: repeat(8, auto);
              align-items: center;
              justify-content: flex-start;
            "
          >
            <p>{{ dhms.sign }}{{ dhms.days }}</p>
            <p>day</p>
            <p>{{ dhms.hours }}</p>
            <p>hr</p>
            <p>{{ dhms.minutes }}</p>
            <p>min</p>
            <p>{{ dhms.seconds }}</p>
            <p>sec</p>
          </div>
          <p class="x-depart-location">
            {{ $t('section.until-departure') }} {{ departCityIata }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { date } from '../../other/utils/helpers/date.js'

const formatDate = timestamp => date.formatUTC('-d -ms.', timestamp)

export default {
  name: 'UpcomingTripSection',
  props: {
    trip: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.now = Date.now()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  data() {
    const { departureCity, arrivalCity, departureDate, returnDate } = this.trip
    return {
      interval: undefined,
      now: Date.now(),
      tripStart: Date.parse(departureDate),
      imageUrl: arrivalCity.media.imageUrl,
      departureDate: formatDate(departureDate),
      returnDate: formatDate(returnDate),
      arriveCityIata: arrivalCity.iataCode,
      arriveCityName: arrivalCity.name,
      arriveCountryName: arrivalCity.region.country.name,
      departCityIata: departureCity.iataCode,
    }
  },
  methods: {
    gotoTripDetails() {
      this.$router.push(
        `/bookings/details?eluderef=${this.trip.eludeReference}`,
      )
    },
  },
  computed: {
    dhms() {
      const duration = this.tripStart - this.now
      const sign = duration >= 0 ? '' : '-'
      const [days, hours, minutes, seconds] = date
        .duration('-d -h -min -sec', Math.abs(duration))
        .split(' ')
      return { days, hours, minutes, seconds, sign }
    },
  },
}
</script>

<style scoped lang="scss">
.x-wrap {
  background: white;
  margin-top: 1rem;
  box-shadow: var(--shadow-app);
  border-radius: var(--border-radius-m);
  cursor: pointer;
  transition: var(--transition-default);
  padding: 1rem 1.2rem;
  min-height: 164px;
  max-width: 400px;
  width: 100%;
  &:hover {
    box-shadow: var(--shadow-app-2);
    background: darker(get('color.white'), 1%);
    & > *:nth-child(n + 5):nth-child(-n + 8) {
      background: darker(#fcfcfc, 1%);
    }
  }
}
.x-wrap:hover .x-img-bg {
  opacity: 0.3;
}
.x-img {
  background-position: center;
  background-size: cover;
  object-position: center;
  object-fit: cover;
  grid-area: img;
  border-radius: 12px;
  margin-right: 0.8rem;
  overflow: hidden;
}
.x-img-bg {
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(-145deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  opacity: 1;
}
.x-img-text {
  color: white;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-indent: 1.5px;
}
.x-title {
  grid-area: title;
  align-self: center;
}
.x-break {
  grid-area: break;
  border-bottom: 1px dashed #ddd;
}
.x-depart {
  grid-area: desc;
  display: grid;
  align-content: center;
  margin-top: 0.4rem;
}
.x-depart-location {
  font-family: DDin;
  color: var(--color-grey);
  font-size: 0.75rem;
}
.x-depart-time > *:nth-child(odd) {
  font-size: 1rem;
  letter-spacing: -0.04em;
}
.x-depart-time > *:nth-child(even) {
  font-family: DDin;
  color: var(--color-grey);
  text-transform: uppercase;
  font-size: 0.6875rem;
  margin-right: 5px;
  margin-top: 0.1rem;
}
</style>
