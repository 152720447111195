<template>
  <div
    v-if="data"
    class="plane-card app-card-clickable"
    @click="$emit('click')"
  >
    <div style="grid-area: outline-plane">
      <IconPlaneOutline />
    </div>
    <div class="plane-card-airport" style="grid-area: departure">
      <p>{{ departureIata }}</p>
      <p>{{ departureCityName }}</p>
    </div>
    <div style="grid-area: fill-plane">
      <IconPlaneRed />
    </div>
    <div class="plane-card-airport" style="grid-area: arrival">
      <p>{{ arrivalIata }}</p>
      <p>{{ arrivalCityName }}</p>
    </div>
    <div class="plane-card-divider" style="grid-area: divider"></div>
    <div class="plane-card-date" style="grid-area: departure-date">
      <p>{{ $t('cards.date') }}</p>
      <p style="font-size: 14px">{{ departureDate }}</p>
    </div>
    <div class="plane-card-date" style="grid-area: departure-time">
      <p>{{ $t('cards.depart') }}</p>
      <p>{{ departureTime }}</p>
    </div>
    <div class="plane-card-date" style="grid-area: arrival-time">
      <p>{{ $t('cards.arrive') }}</p>
      <p>{{ arrivalTime }}</p>
    </div>
  </div>
</template>

<script>
import IconPlaneOutline from '../../components-rf/icons/bookings/IconPlaneOutline.vue'
import IconPlaneRed from '../../components-rf/icons/bookings/IconPlaneRed.vue'
import { date } from '../../other/utils/helpers/date.js'

const formatDate = time => date.formatUTC('-d -ms, -ds', time)
const formatTime = time => date.formatUTC('-h2:-min2', time, true)

export default {
  name: 'BookingFlightCard',
  props: { data: Object },
  components: { IconPlaneOutline, IconPlaneRed },
  data() {
    const {
      departureAirport,
      departureDateTimeTimestamp,
      arrivalAirport,
      arrivalDateTimeTimestamp,
    } = this.data

    return {
      departureIata: departureAirport.iataCode,
      departureCityName: departureAirport.city.name,
      departureDate: formatDate(
        date.getGoTimestamp(departureDateTimeTimestamp),
      ),
      departureTime: formatTime(
        date.getGoTimestamp(departureDateTimeTimestamp),
      ),
      arrivalIata: arrivalAirport.iataCode,
      arrivalCityName: arrivalAirport.city.name,
      // arrivalDate: formatDate(date.getGoTimestamp(arrivalDateTimeTimestamp)),
      arrivalTime: formatTime(date.getGoTimestamp(arrivalDateTimeTimestamp)),
    }
  },
}
</script>

<style scoped lang="scss">
.plane-card {
  font-family: DDin;
  display: grid;
  grid-template-areas: 'outline-plane departure fill-plane arrival' 'divider divider divider divider' 'departure-date departure-time x arrival-time';
  align-items: center;
  grid-template-rows: 80px auto auto;
  padding: 0;
  max-width: 400px;

  & > *:not(.plane-card-divider) {
    height: 100%;
    min-height: 80px;
    padding: 0.5rem 0.5rem;
    display: grid;
    align-content: center;
    @include s {
      padding: 0.5rem 1rem;
    }
  }
  & > *:nth-child(5n + 1) {
    border-right: 1px dashed #ddd;
  }
}
.plane-card-divider {
  height: 1px;
  background: #ddd;
}
.plane-card-date {
  & > p:first-child {
    color: #a0a0a0;
    font-size: 0.75rem;
    letter-spacing: 0.2px;
  }
  & > p:last-child {
    font-size: 1rem;
  }
}

.plane-card-airport {
  & > p:first-child {
    font-size: 1.125rem;
    font-family: LuciferSans;
    letter-spacing: 0.1em;
  }
  & > p:last-child {
    font-size: 0.75rem;
    color: #a0a0a0;
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
