<template>
  <div>
    <transition name="slide" appear>
      <div class="container-fixed" style="display: grid; gap: 4px">
        <p v-if="userName" style="margin-top: -2px" class="app-title">
          {{ $t('home.bonjour') }} {{ userName }}.
        </p>
        <div
          data-test="weather-bar"
          class="weather"
          style="
            display: grid;
            gap: 1rem;
            grid-template-columns: auto auto 1fr;
            text-transform: uppercase;
            align-items: center;
            justify-content: flex-start;
          "
        >
          <icon icon="Weather" />
          <p
            v-if="weatherText"
            class="x-subtitle"
            style="cursor: pointer"
            @click="toggleTemperatureUnit"
          >
            {{ weatherText }}
          </p>
          <p v-else class="x-subtitle">{{ $t('home.weather-unavailable') }}</p>
          <p class="x-subtitle" style="justify-self: flex-end">{{ date }}</p>
        </div>
      </div>
    </transition>
    <break style="margin-top: 1rem" />
    <div
      style="display: grid; gap: 1.75rem; margin-top: 1.5rem"
      class="container-fixed"
    >
      <CurrentTripSection
        v-if="currentBooking"
        id="current-trip"
        button
        :trip="currentBooking"
      />
      <UpcomingTripSection
        v-if="upcomingBooking"
        id="upcoming-trip"
        data-test="upcoming-trip"
        :trip="upcomingBooking"
      />
      <FeaturedLocationsSection
        v-if="featuredLocations.length"
        data-test="recommended-trip"
        :featured-locations="featuredLocations"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CurrentTripSection from '../../components/home/CurrentTripSection.vue'
import FeaturedLocationsSection from '../../components/home/FeaturedLocationsSection.vue'
import UpcomingTripSection from '../../components/home/UpcomingTripSection.vue'

export default {
  name: 'Home',
  components: {
    FeaturedLocationsSection,
    UpcomingTripSection,
    CurrentTripSection,
  },
  created() {
    this.getWeatherByCoordinates()
    if (!this.featuredLocations.length) this.getFeaturedLocations()
    if (this.isLoggedIn) this.getHomeBookings()
  },
  computed: {
    ...mapState('BookingModule', ['currentBooking', 'upcomingBooking']),
    ...mapState('AuthModule', ['isLoggedIn']),
    ...mapState('UserModule', ['user']),
    ...mapState('WeatherModule', ['weather']),
    ...mapState('ClientModule', ['ipAddress']),
    ...mapState('LocationModule', ['featuredLocations']),
    ...mapGetters('WeatherModule', ['getTemperature']),
    userName() {
      return this.user?.firstName
    },
    weatherText() {
      if (!this.weather) return ''
      const { currentTemperature, weather: weatherTitle } = this.weather
      const temperature = this.getTemperature(currentTemperature)

      return `${temperature} ${weatherTitle}`
    },
    date() {
      let date = new Date()
      return (
        date.toLocaleDateString('en-Us', {
          day: 'numeric',
          month: 'long',
          timeZone: this.$timezone,
        }) +
        ', ' +
        date.getFullYear()
      )
    },
  },
  methods: {
    ...mapActions('WeatherModule', [
      'toggleTemperatureUnit',
      'getWeatherByCoordinates',
    ]),
    ...mapActions('LocationModule', ['getFeaturedLocations']),
    ...mapActions('BookingModule', ['getHomeBookings']),
  },
}
</script>

<style scoped lang="scss">
.x-subtitle {
  letter-spacing: 0.2px;
}
</style>
