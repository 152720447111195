<template>
  <grid class="x-wrap" gtc="repeat(4, auto)" @click="$emit('click')">
    <icon icon="Hotel" />
    <div class="x-name">
      <p>{{ display.name }}</p>
      <p>{{ display.address }}, {{ display.cityName }}</p>
    </div>
    <div class="x-date">
      <p>{{ $t('cards.date') }}</p>
      <p>{{ display.checkIn }}</p>
    </div>
    <div class="x-date">
      <p>{{ $t('cards.guests') }}</p>
      <p>{{ display.guests }}</p>
    </div>
    <div class="x-null"></div>
    <div class="x-date">
      <p>{{ $t('cards.duration') }}</p>
      <p>
        {{ display.nights }}
        {{ display.nights === 1 ? $t('cards.night') : $t('cards.nights') }}
      </p>
    </div>
  </grid>
</template>

<script>
import { date } from '../../other/utils/helpers/date'
import { getNumberOfNights } from './util'
const formatDate = timestamp => date.formatUTC('-d -ms, -ds', timestamp)

export default {
  name: 'BookingHotelCard',
  props: { data: Object },
  computed: {
    hotel: vm => vm.data.itinerary.hotel,
    display() {
      const hotel = this.hotel

      return {
        name: hotel.name,
        address: hotel.address,
        cityName: hotel.city?.name,
        checkIn: formatDate(date.getGoTimestamp(hotel.checkInDateTimestamp)),
        nights: getNumberOfNights(
          date.getGoTimestamp(hotel.checkInDateTimestamp),
          date.getGoTimestamp(hotel.checkOutDateTimestamp),
        ),
        guests: this.guests,
      }
    },
    guests() {
      const { adults, children, infants } = this.data
      const guests = []
      if (adults)
        guests.push(
          `${adults} ${
            adults > 1 ? this.$t('cards.adults') : this.$t('cards.adult')
          }`,
        )
      if (children)
        guests.push(
          `${children} ${
            children > 1 ? this.$t('cards.children') : this.$t('cards.child')
          }`,
        )
      if (infants)
        guests.push(
          `${infants} ${
            infants > 1 ? this.$t('cards.infants') : this.$t('cards.infant')
          }`,
        )
      return guests.join(', ')
    },
  },
}
</script>

<style scoped lang="scss">
.x-wrap > *:nth-child(n + 3):nth-child(-n + 6) {
  transition: var(--transition-default);
  border-top: 1px solid #ddd;
  background: #fcfcfc;
}
.x-wrap {
  transition: var(--transition-default);
  font-family: DDin;
  box-shadow: var(--shadow-app);
  background: white;
  border-radius: var(--border-radius-m);
  cursor: pointer;
  align-items: center;
  grid-auto-rows: auto;
  max-width: 400px;
  width: 100%;
  &:hover {
    box-shadow: var(--shadow-app-2);
    background: darker(get('color.white'), 1%);
    & > *:nth-child(n + 3):nth-child(-n + 6) {
      background: darker(#fcfcfc, 1%);
    }
  }
}
.x-wrap > * {
  height: 100%;
  display: grid;
  align-content: center;
  padding: 0.5rem;
  @include s {
    padding: 1rem;
  }
  min-height: 78px;
}

.x-wrap > *:first-child {
  border-right: 1px dashed #ddd;
  justify-content: center;
}
.x-wrap > *:nth-child(3) {
  border-right: 1px dashed #ddd;
  border-bottom-left-radius: 9px;
}
.x-wrap > *:last-child {
  border-bottom-right-radius: 9px;
}

.x-date > p:first-child {
  color: #a0a0a0;
  font-size: 0.75rem;
  letter-spacing: 0.2px;
}
.x-date > p:last-child {
  font-size: 1rem;
}
.x-name {
  grid-column: 2 / 5;
  grid-row: 1 / 1;
  padding: 0.6rem 1rem;
}
.x-name > p:first-child {
  font-family: SpaceMono;
  font-size: 0.9375rem;
  letter-spacing: 0.1em;
  max-width: fit-content;
  line-height: 1.2;
}
.x-name > p:last-child {
  font-size: 0.75rem;
  margin-top: 4px;
  color: #a0a0a0;
}
</style>
