<template>
  <transition name="fade" appear>
    <div style="transition-delay: 50ms">
      <x-label>{{ $t('section.current-trip') }}</x-label>
      <component
        :is="activeSegment._component"
        :data="activeSegment._data"
        class="current-section"
        style="margin-top: 1rem"
        @click="gotoTripDetails()"
      />
    </div>
  </transition>
</template>

<script>
import BookingFlightCard from '../../components-rf/bookings/BookingFlightCard.vue'
import BookingHotelCard from '../../components-rf/bookings/BookingHotelCard.vue'
import { date } from '../../other/utils/helpers/date'

const mapSegment = ({ segment, data, component }) => ({
  ...segment,
  _timeframe: {
    start: segment.departureDateTimeTimestamp
      ? date.getGoTimestamp(segment.departureDateTimeTimestamp)
      : segment.checkInDateTimestamp
      ? date.getGoTimestamp(segment.checkInDateTimestamp)
      : '',
    end: segment.arrivalDateTimeTimestamp
      ? date.getGoTimestamp(segment.arrivalDateTimeTimestamp)
      : segment.checkOutDateTimestamp
      ? date.getGoTimestamp(segment.checkOutDateTimestamp)
      : '',
  },
  _data: data,
  _component: component,
})

export default {
  name: 'CurrentTripSection',
  props: {
    trip: {
      type: Object,
      required: true,
    },
  },
  computed: {
    segments() {
      const itinerary = this.trip.itinerary
      const departureSegments =
        itinerary.departureFlight?.segmentsList?.map(segment =>
          mapSegment({ segment, data: segment, component: BookingFlightCard }),
        ) ?? []
      const hotel = mapSegment({
        segment: itinerary.hotel,
        data: this.trip,
        component: BookingHotelCard,
      })
      const returnSegments =
        itinerary.returnFlight?.segmentsList?.map(segment =>
          mapSegment({ segment, data: segment, component: BookingFlightCard }),
        ) ?? []
      return [...departureSegments, hotel, ...returnSegments]
    },
    activeSegment() {
      const now = Date.now()
      let activeSegment = undefined
      this.segments.forEach(seg => {
        if (activeSegment) return
        const { start, end } = seg._timeframe
        if (now < start) activeSegment = seg
        else if (now > start && now < end) activeSegment = seg
      })
      if (!activeSegment) activeSegment = this.segments.at(-1)
      return activeSegment
    },
  },
  methods: {
    gotoTripDetails() {
      this.$router.push(
        `/bookings/details?eluderef=${this.trip.eludeReference}`,
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
